$theme-colors: (
	"success": #32c79f,
	"secondary": #192631,
	"primary": #37AEEC,
	"warning": #FFBC45,
	"danger": #e34e53,
	"dark": #797f85,
);
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1000px,
	xl: 1450px
);
// $input-border-color: #f7553d;
// $input-disabled-bg:#F0F2F5;
$input-placeholder-color: #fff;
$input-focus-border-color: #fff;
$input-color: #fff;
$input-padding-x: .5rem;
$input-padding-y: .2rem;
// $form-feedback-invalid-color:#FF6A6A;
$input-border-radius: 0;
$input-border-color: #797f85;
$input-focus-box-shadow: 0;
$form-feedback-icon-invalid: none;
$input-bg: rgba(#15212c, .3);
$label-margin-bottom: 3px;

$btn-box-shadow: none;
$btn-focus-box-shadow: none !important;
$btn-border-width: 2px;
$btn-border-radius: 10px;
$btn-border-radius-sm: 8px;
$form-group-margin-bottom: 6px;

$body-color: #fff;



$link-color: #000;
$link-hover-color: #000;

$rounded-pill: 5px;
$text-muted: #797f85;
// $custom-control-indicator-checked-color: red;


// $font-size-base: 1.4rem;